import MegamenuTopics from "components/Megamenu/MegamenuTopics"
import MegamenuVolumes from "components/Megamenu/MegamenuVolumes"
import { useState } from "react"

import Grid from "@mui/material/Grid"
import Popover from "@mui/material/Popover"
import { grey } from "@mui/material/colors"
import { styled } from "@mui/material/styles"

import { IMegamenuNode } from "data/contentData/api/content"

interface IProps {
    anchorEl: HTMLButtonElement | null
    open: boolean
    nodes?: IMegamenuNode[]
    handleClose(): void
}

export default function MegamenuPopover(props: IProps) {
    const { anchorEl, open, nodes = [], handleClose } = props

    const [selected, setSelected] = useState(0)

    const volume = nodes[selected]

    const handleVolumeClick = (path: number) => () => setSelected(path)

    return (
        <StyledPopover
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            onClose={handleClose}
            open={open}
            marginThreshold={-16}
            transformOrigin={{ horizontal: "left", vertical: "top" }}>
            <Grid container>
                <Volumes
                    item
                    sm={3}
                    xs={12}>
                    <MegamenuVolumes
                        onVolumeClick={handleVolumeClick}
                        onCloseClick={handleClose}
                        selected={selected}
                        volumes={nodes}
                    />
                </Volumes>
                <Topics
                    container
                    item
                    sm={9}
                    xs={12}>
                    <MegamenuTopics
                        onCloseClick={handleClose}
                        volume={volume}
                    />
                </Topics>
            </Grid>
        </StyledPopover>
    )
}

const StyledPopover = styled(Popover)(({ theme }) => ({
    "& .MuiPopover-root": {
        display: "flex",
        marginLeft: -32
    },
    "& .MuiPopover-paper": {
        borderRadius: 0,
        maxHeight: "75vh",
        width: "min(100%, 1280px)",
        "&::-webkit-scrollbar": {
            width: "4px"
        },
        "&::-webkit-scrollbar-track": {
            marginTop: "2px",
            backgroundColor: theme.palette.grey[200]
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: grey[500]
        }
    }
}))

const Volumes = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    minHeight: "25vh",
    padding: theme.spacing(3, 0, 3, 3)
}))

const Topics = styled(Grid)(({ theme }) => ({
    height: "100%",
    padding: theme.spacing(3)
}))
