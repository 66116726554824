import { PortableTextTypeComponentProps } from "@portabletext/react"
import clsx from "clsx"
import { Icon } from "components/Icon"
import { t } from "locales"

import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

type VideoService = "vimeo" | "youtube" | "exorlive"

interface VideoEmbedProps {
    service: VideoService
    id: string
    title: string
    caption?: string
}
export default function VideoEmbed({
    value
}: PortableTextTypeComponentProps<VideoEmbedProps>) {
    let src: string

    switch (value.service) {
        case "vimeo":
            src = `https://player.vimeo.com/video/${value.id}`
            break
        case "youtube":
            src = `https://www.youtube.com/embed/${value.id}`
            break
        case "exorlive":
            src = `https://exorlive.com/video/?ex=${value.id}`
            break
        default:
            if (process.env.NODE_ENV === "production") {
                return null
            }
            throw new Error(`Unknown video service: ${value.service}`)
    }

    return (
        <>
            <Wrapper>
                <IFrame
                    allowFullScreen
                    allow="autoplay; fullscreen"
                    className={clsx("no-print")}
                    src={src}
                    title={value.title}
                />

                <VideoOnPrint>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid
                            item
                            xs={1}>
                            <PlayIcon>
                                <Icon name="playCircle" />
                            </PlayIcon>
                        </Grid>
                        <Grid
                            item
                            xs>
                            <Typography variant="inherit">
                                {t["on-print"]["video-placeholder"]}
                            </Typography>
                        </Grid>
                    </Grid>
                </VideoOnPrint>
            </Wrapper>
            {value.caption && (
                <Typography
                    className={clsx("no-print")}
                    variant="caption">
                    {value.caption}
                </Typography>
            )}
        </>
    )
}

const Wrapper = styled("div")({
    paddingBottom: "56.25%",
    position: "relative",
    "@media print": {
        paddingBottom: 0
    }
})

const IFrame = styled("iframe")({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",

    "@media print": {
        display: "none !important"
    }
})

const VideoOnPrint = styled("div")({
    display: "none",
    "@media print": {
        display: "block"
    }
})

const PlayIcon = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2)
}))
