import { Favorites } from "components/Layout/Header/Favorites"
import Feedback from "components/Layout/Header/Feedback"
import Logo from "components/Layout/Header/Logo"
import SearchField from "components/Layout/Header/SearchField"
import Megamenu from "components/Megamenu/Megamenu"
import Profile from "components/ProfilePopover/Profile"
import { useToggleOnScroll } from "hooks/useScrollSpy"

import AppBar from "@mui/material/AppBar"
import Container from "@mui/material/Container"
import { styled } from "@mui/material/styles"

export function Header() {
    const show = useToggleOnScroll()

    return (
        <AppBar
            elevation={0}
            position="sticky"
            className="no-print">
            <StyledContainer show={show.toString()}>
                <Megamenu />
                <Logo />
                <StyledSearchInput show={show.toString()}>
                    <SearchField />
                </StyledSearchInput>
                <StyledHeaderMenu>
                    <Feedback />
                    <Favorites />
                    <Profile />
                </StyledHeaderMenu>
            </StyledContainer>
        </AppBar>
    )
}

export function HeaderSimple() {
    return (
        <AppBar
            elevation={0}
            className="classes.hideOnPrint"
            position="sticky">
            <Container
                fixed
                disableGutters>
                <StyledContainer show={"false"}>
                    <Logo simple />
                </StyledContainer>
            </Container>
        </AppBar>
    )
}

const StyledContainer = styled("div")<{ show: string }>(({ show, theme }) => ({
    width: `min(100%, ${theme.breakpoints.values.lg}px)`,
    padding: theme.spacing(0, 2),
    alignItems: "center",
    columnGap: theme.spacing(2),

    display: "grid",
    gridTemplateColumns: "48px 1fr auto auto",
    gridTemplateRows: show === "true" ? "64px 64px" : "64px",

    [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "48px auto 1fr auto",
        gridTemplateRows: "64px",
        margin: "0 auto"
    }
}))

const StyledHeaderMenu = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(0.5),

    [theme.breakpoints.only("xs")]: {
        gridColumn: "3 / 5"
    },

    [theme.breakpoints.up("sm")]: {
        gap: theme.spacing(2)
    }
}))

const StyledSearchInput = styled("div")<{ show: string }>(
    ({ show, theme }) => ({
        display: show === "true" ? "block" : "none",
        gridColumn: "1 / 5",
        gridRow: 2,
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(0, 2),
            display: "block",
            gridColumn: 3,
            gridRow: 1
        }
    })
)
