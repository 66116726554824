import { Drawer } from "components/Drawer/Drawer"
import { Icon } from "components/Icon"
import Link from "components/Link"
import useUser from "hooks/useUser"
import { t } from "locales"
import { MouseEvent, useEffect, useState } from "react"

import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import { FavoriteSorterType } from "data/contentData/api/user"
import { useFavorites } from "data/contentData/hooks/user.hooks"
import { toLocalDate } from "lib/string/date-to-locale-date-string"

export function Favorites() {
    const { authenticated } = useUser()
    const { favorites, remove, sort, sortBy } = useFavorites()

    const [anchorEl, setAnchorEl] = useState<null | any>(null)
    const [open, setOpen] = useState(false)

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    const [sortState, setSortState] = useState<string>(sortBy)

    useEffect(() => {
        setSortState(sortBy)
    }, [sortBy])

    const handleSort = (
        _event: MouseEvent<HTMLElement>,
        value: FavoriteSorterType | null
    ) => {
        if (value) {
            setSortState(value)
            sort(value)
        }
    }

    if (!authenticated) {
        return null
    }

    return (
        <>
            <Tooltip title={t["favorite"]["favorites"]}>
                <IconButton
                    color="inherit"
                    ref={anchorEl}
                    aria-label={t["favorite"]["favorites"]}
                    onClick={handleOpen}
                    sx={{ p: 0.5 }}
                    size="large">
                    <Icon name="favorite" />
                </IconButton>
            </Tooltip>

            <Drawer
                header={
                    <Typography
                        align="center"
                        variant="subtitle2">
                        {t["favorite"]["favorites"]}
                    </Typography>
                }
                position="right"
                open={open}
                onClose={handleClose}>
                {favorites?.length ? (
                    <>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-start"
                            style={{ padding: 16 }}>
                            <Typography
                                sx={{ mr: 1 }}
                                variant="body2">
                                {t["favorite"]["sort-by"]}
                            </Typography>
                            <ToggleButtonGroup
                                aria-label={t["favorite"]["sort"]["options"]}
                                exclusive
                                size="small"
                                value={sortState}
                                onChange={handleSort}>
                                <ToggleButton
                                    sx={{ textTransform: "capitalize" }}
                                    value="abc"
                                    aria-label={t["favorite"]["sort"]["abc"]}>
                                    <Icon
                                        name="abcSort"
                                        fontSize="small"
                                        sx={{ mr: 0.5 }}
                                    />
                                    {t["favorite"]["sort"]["abc"]}
                                </ToggleButton>
                                <ToggleButton
                                    sx={{ textTransform: "capitalize" }}
                                    value="time"
                                    aria-label={t["favorite"]["sort"]["time"]}>
                                    <Icon
                                        name="timeSort"
                                        fontSize="small"
                                        sx={{ mr: 0.5 }}
                                    />
                                    {t["favorite"]["sort"]["time"]}
                                </ToggleButton>

                                <ToggleButton
                                    sx={{ textTransform: "capitalize" }}
                                    value="template"
                                    aria-label={
                                        t["favorite"]["sort"]["template"]
                                    }>
                                    <Icon
                                        name="categorySort"
                                        fontSize="small"
                                        sx={{ mr: 0.5 }}
                                    />
                                    {t["favorite"]["sort"]["template"]}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <List disablePadding>
                            {favorites.map(favorite => (
                                <MenuItem
                                    key={favorite.url}
                                    onClick={handleClose}
                                    tabIndex={0}>
                                    <Link href={favorite.url}>
                                        <ListItemText
                                            primary={favorite.name}
                                            primaryTypographyProps={{
                                                noWrap: true
                                            }}
                                            secondary={
                                                <>
                                                    <Typography
                                                        color="textPrimary"
                                                        component="span"
                                                        variant="caption">
                                                        {favorite.template}
                                                    </Typography>
                                                    <Typography variant="caption">
                                                        {" — "}
                                                        {favorite.timestamp
                                                            ? toLocalDate(
                                                                favorite.timestamp,
                                                                process.env
                                                                    .NEXT_PUBLIC_LOCALE
                                                            )
                                                            : null}
                                                    </Typography>
                                                </>
                                            }
                                        />
                                    </Link>
                                    <ListItemSecondaryAction>
                                        <Tooltip
                                            title={
                                                t["favorite"][
                                                "remove-from-favorites"
                                                ]
                                            }>
                                            <IconButton
                                                aria-label={
                                                    t["favorite"][
                                                    "remove-from-favorites"
                                                    ]
                                                }
                                                edge="end"
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    remove(favorite.url)
                                                }}
                                                size="large">
                                                <Icon
                                                    name="delete"
                                                    color="error"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </MenuItem>
                            ))}
                        </List>
                    </>
                ) : (
                    <Grid
                        container
                        justifyContent="center"
                        style={{ height: "100%" }}>
                        <Typography
                            variant="caption"
                            style={{ marginTop: "3em" }}>
                            {t["favorite"]["none"]}
                        </Typography>
                    </Grid>
                )}
            </Drawer>
        </>
    )
}
