import { useQuery } from "react-query"
import { OptionsType } from "types/shared.types"

import {
    ISearchResult,
    ISearchSuggestionGroup,
    getLatestArticles,
    getSearch,
    getSearchAutocomplete,
    getSearchSuggestions
} from "data/contentData/api/search"

export enum SearchCacheKeys {
    SEARCH = "search",
    SUGGESTIONS = "search-suggestions",
    FACETS = "search-facets",
    LATEST_ARTICLES = "latest-articles"
}

export interface ISearchSuggestionsAndAutocomlete {
    suggestionGroups: ISearchSuggestionGroup[]
    autocomplete: string[]
}

export function useGetSearchSuggestions(
    q: string,
    options?: OptionsType<ISearchSuggestionsAndAutocomlete>
) {
    return useQuery<ISearchSuggestionsAndAutocomlete, Error>(
        [SearchCacheKeys.SUGGESTIONS, q],
        async () => {
            const suggestionGroups = await getSearchSuggestions(q)
            const autocomplete = await getSearchAutocomplete(q)

            return {
                suggestionGroups,
                autocomplete
            }
        },
        {
            ...options
        }
    )
}

interface ISearchParams {
    q?: string | string[]
    templateSubGroup?: string
    limit?: number
    skip?: number
    sortType?: number
    fuzzy?: boolean
    editorMode?: boolean
    bypassCache?: boolean
    options?: OptionsType<ISearchResult>
}

export function useGetSearch({
    q,
    templateSubGroup,
    limit,
    skip,
    sortType,
    fuzzy,
    editorMode,
    bypassCache,
    options
}: ISearchParams) {
    // Custom increased staleTime to avoid multiple requests. Facet count
    // from initial search (without type filter) should persist when filtering
    return useQuery<ISearchResult, Error>(
        [
            SearchCacheKeys.SEARCH,
            q,
            limit,
            skip,
            sortType,
            fuzzy,
            templateSubGroup,
            editorMode,
            bypassCache
        ],
        async () => {
            return await getSearch(
                q,
                templateSubGroup,
                limit,
                skip,
                sortType,
                fuzzy,
                editorMode,
                bypassCache
            )
        },
        {
            staleTime: 1000 * 60,
            ...options
        }
    )
}

export type LatestArticleType = {
    timestamp: Date
    title: string
    url: string
}

export function useGetLatestArticles(
    templateGroup: string,
    limit: number,
    includeNew: boolean,
    includeUpdated: boolean,
    includeRevised: boolean,
    bypassCache?: boolean,
    options?: OptionsType<LatestArticleType[]>
) {
    return useQuery<LatestArticleType[], Error>(
        [SearchCacheKeys.LATEST_ARTICLES],
        async () =>
            await getLatestArticles(
                templateGroup,
                limit,
                includeNew,
                includeUpdated,
                includeRevised,
                bypassCache
            ),
        {
            ...options
        }
    )
}
