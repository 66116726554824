
import dynamic from "next/dynamic"
const components = {
  alcoholaudit: dynamic(() => import("./alcoholaudit")),
  auditc: dynamic(() => import("./auditc")),
  barthel: dynamic(() => import("./barthel")),
  bodymassindex: dynamic(() => import("./bodymassindex")),
  braden: dynamic(() => import("./braden")),
  calorieneeds: dynamic(() => import("./calorieneeds")),
  cha2ds2vasc: dynamic(() => import("./cha2ds2vasc")),
  crb65: dynamic(() => import("./crb65")),
  dehydration: dynamic(() => import("./dehydration")),
  dementiaquestionnaire: dynamic(() => import("./dementiaquestionnaire")),
  docssf: dynamic(() => import("./docssf")),
  drugsdriverlicence: dynamic(() => import("./drugsdriverlicence")),
  egfr: dynamic(() => import("./egfr")),
  functionalreach: dynamic(() => import("./functionalreach")),
  gbsdementia: dynamic(() => import("./gbsdementia")),
  gcs: dynamic(() => import("./gcs")),
  gout: dynamic(() => import("./gout")),
  hasbled: dynamic(() => import("./hasbled")),
  hba1c: dynamic(() => import("./hba1c")),
  hearingloss: dynamic(() => import("./hearingloss")),
  ipss: dynamic(() => import("./ipss")),
  ldlcholesterol: dynamic(() => import("./ldlcholesterol")),
  lorenz: dynamic(() => import("./lorenz")),
  majordepression: dynamic(() => import("./majordepression")),
  mmsenr3: dynamic(() => import("./mmsenr3")),
  news2: dynamic(() => import("./news2")),
  niigata: dynamic(() => import("./niigata")),
  norrisk1: dynamic(() => import("./norrisk1")),
  norrisk2: dynamic(() => import("./norrisk2")),
  obsdemens: dynamic(() => import("./obsdemens")),
  pefnomogram: dynamic(() => import("./pefnomogram")),
  pevs: dynamic(() => import("./pevs")),
  rdrs2: dynamic(() => import("./rdrs2")),
  smoke: dynamic(() => import("./smoke")),
  startback: dynamic(() => import("./startback")),
  sukks: dynamic(() => import("./sukks")),
  term: dynamic(() => import("./term")),
  tweak: dynamic(() => import("./tweak")),
  vertigo: dynamic(() => import("./vertigo")),
  waistheightindex: dynamic(() => import("./waistheightindex")),
  waisthipindex: dynamic(() => import("./waisthipindex")),
  warfarindosage: dynamic(() => import("./warfarindosage")),
  weightloss: dynamic(() => import("./weightloss")),
  wellsdvt: dynamic(() => import("./wellsdvt")),
  wellsle: dynamic(() => import("./wellsle")),
}

export default components