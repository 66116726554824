import { PortableTextTypeComponentProps } from "@portabletext/react"
import { IContentImage } from "components/PortableText/Types/Image"
import ImageWithGalleryLink from "components/PortableText/Types/ImageWithGalleryLink"

import { styled } from "@mui/material/styles"

type ImgListProps = PortableTextTypeComponentProps<{
    images: IContentImage[]
}>

export default function ImgList({ value }: ImgListProps) {
    return (
        <ImgListContainer>
            {value.images.map(image => (
                <ImageWithGalleryLink
                    index={image.index}
                    key={image.index}
                    value={{ ...image, size: "large" }}
                    isInline={false}
                    renderNode={() => void 0}
                />
            ))}
        </ImgListContainer>
    )
}

const ImgListContainer = styled("div")(() => ({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
    alignItems: "start",
    gap: "1rem"
}))
