import ButtonLink from "components/Link/ButtonLink"
import EmptyAnchor from "components/Link/EmptyAnchor"
import External, {
    SafeExternalLink,
    isSafeExternal
} from "components/Link/External"
import type { LinkProps } from "components/Link/Link"
import { general } from "config/config"
// eslint-disable-next-line no-restricted-imports
import NextLink from "next/link"
import { forwardRef } from "react"

import { ButtonProps as MuiButtonProps } from "@mui/material/Button"
import MuiLink, { LinkProps as MuiLinkProps } from "@mui/material/Link"
import { decodeHrefString } from "lib/string/decode-href-string"

function isNonNavigational(href: string) {
    const url = new URL(general.appHost)

    return (
        ["#", "?", "mailto:", "tel:"].some(s => href.startsWith(s)) ||
        href.includes(`intro.${url.host}`)
    )
}

function isInternalLink(href: string) {
    if (href.startsWith("/")) {
        return true
    }

    if (href.includes(process.env.NEXT_PUBLIC_ID.replace("-", "."))) {
        return true
    }

    return false
}

function BaseLink({
    innerRef,
    button,
    href,
    as,
    prefetch = false,
    replace,
    scroll,
    shallow,
    ...props
}: LinkProps & { innerRef: any }) {
    if (!href) return <EmptyAnchor>{props.children}</EmptyAnchor>

    href = decodeHrefString(href)

    if (isInternalLink(href)) {
        const nextLinkProps = { as, href, prefetch, replace, scroll, shallow }
        if (button) {
            return (
                <NextLink
                    passHref
                    {...nextLinkProps}>
                    <ButtonLink
                        ref={innerRef}
                        {...(props as MuiButtonProps<"a">)}
                    />
                </NextLink>
            )
        }

        return (
            <NextLink
                passHref
                {...nextLinkProps}
                shallow={false}>
                <MuiLink
                    ref={innerRef}
                    underline="hover"
                    {...(props as MuiLinkProps)}
                />
            </NextLink>
        )
    }

    if (isNonNavigational(href)) {
        if (button) {
            return (
                <ButtonLink
                    href={href}
                    ref={innerRef}
                    {...(props as MuiButtonProps<"a">)}
                />
            )
        } else {
            return (
                <MuiLink
                    href={href}
                    ref={innerRef}
                    underline="hover"
                    {...(props as MuiLinkProps)}
                />
            )
        }
    }

    if (isSafeExternal(href)) {
        return (
            <SafeExternalLink
                button={button}
                href={href}
                {...props}
            />
        )
    }

    // renders an external link and a Dialog
    return (
        <External
            button={button}
            href={href}
            {...props}
        />
    )
}

/**
 * @note ⚠ If not explicitly defining `button={false}`,
 * the inferred types will be a union of
 * `MuiButtonProps` and `MuiLinkProps`, instead of only `MuiLinkProps`.
 * This is because there is no way to create a discriminated union property
 * @see https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#discriminating-unions
 * with a default value or make it optional.
 *
 * This will only affect the types inferred in the IDE.
 */
const Link = forwardRef<any, LinkProps>(function Link(props, ref) {
    return (
        <BaseLink
            {...props}
            innerRef={ref}
        />
    )
})

export default Link
