import { PortableTextTypeComponentProps } from "@portabletext/react"
import PortableText from "components/PortableText/PortableText"

import MuiTable from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { grey } from "@mui/material/colors"
import { styled } from "@mui/material/styles"

interface TableProps {
    caption?: string
    rows: Array<
        Array<{
            blockContent: string
            colSpan?: number
            rowSpan?: number
            style?: string
        }>
    >
    style?: string
}

export default function Table({
    value
}: PortableTextTypeComponentProps<TableProps>) {
    const classes = value.style?.split(",").join(" ") ?? ""

    return (
        <TableWrapper>
            <MuiTable
                sx={{ m: 0 }}
                className={classes}>
                {value.caption && (
                    <caption style={{ captionSide: "top", padding: 0 }}>
                        <PortableText value={JSON.parse(value.caption)} />
                    </caption>
                )}
                <TableBody>
                    {value.rows.map((row, rowIndex) => (
                        <TableRow
                            style={{ overflowWrap: "break-word" }}
                            // eslint-disable-next-line react/no-array-index-key
                            key={rowIndex}>
                            {row.map(
                                (
                                    { blockContent, style, ...props },
                                    cellIndex
                                ) => (
                                    <StyledTableCell
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={cellIndex}
                                        {...props}
                                        sx={{
                                            fontWeight:
                                                style === "em" ? 700 : 400
                                        }}>
                                        <PortableText
                                            value={JSON.parse(blockContent)}
                                            wrapImgWithGalleryLink
                                        />
                                    </StyledTableCell>
                                )
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </MuiTable>
        </TableWrapper>
    )
}

const TableWrapper = styled("div")(({ theme }) => ({
    overflowX: "auto",
    margin: theme.spacing(2, 0),
    "&::-webkit-scrollbar": {
        width: "4px"
    },
    "&::-webkit-scrollbar-track": {
        marginTop: "2px",
        backgroundColor: theme.palette.grey[200]
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: grey[500]
    }
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(0, 1),
    fontSize: "14px",
    verticalAlign: "top",
    "& ul": {
        paddingLeft: theme.spacing(2)
    }
}))
