import { Icon } from "components/Icon"
import Link from "components/Link"

import Divider from "@mui/material/Divider"
import Hidden from "@mui/material/Hidden"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import { styled, useTheme } from "@mui/material/styles"

import { IMegamenuNode } from "data/contentData/api/content"

interface MegamenuVolumesProps {
    volumes: IMegamenuNode[]
    selected: number
    onVolumeClick(path: number): (event: any) => void
    onCloseClick(): void
}

export default function MegamenuVolumes(props: MegamenuVolumesProps) {
    const { volumes, selected, onVolumeClick, onCloseClick } = props
    const theme = useTheme()

    const listVolumes = volumes.filter(p => p.type === "List")
    const nonListVolumes = volumes.filter(p => p.type !== "List")

    return (
        <>
            <List disablePadding>
                {listVolumes.map((volume, index) => {
                    const _selected = index === selected
                    return (
                        <li key={volume.url}>
                            <ListItem
                                button
                                disableRipple
                                sx={{
                                    backgroundColor: "transparent",
                                    color: "common.white",

                                    "&.Mui-selected": {
                                        backgroundColor: "background.default",
                                        color: "secondary.dark",

                                        "&:hover": {
                                            backgroundColor:
                                                "background.default"
                                        }
                                    },

                                    "&:hover": {
                                        backgroundColor: "action.selected"
                                    }
                                }}
                                onClick={onVolumeClick(index)}
                                selected={_selected}>
                                <Hidden smDown>
                                    <ListItemIcon>
                                        <Icon
                                            style={{
                                                color: _selected
                                                    ? theme.palette.secondary
                                                          .dark
                                                    : "white"
                                            }}
                                            name={volume.icon ?? ""}
                                        />
                                    </ListItemIcon>
                                </Hidden>
                                <ListItemText
                                    primary={
                                        <Typography
                                            fontWeight={700}
                                            component="span">
                                            {volume.name}
                                        </Typography>
                                    }
                                    primaryTypographyProps={{
                                        noWrap: true
                                    }}
                                />
                            </ListItem>
                        </li>
                    )
                })}
            </List>
            <Divider sx={{ my: 2 }} />
            <List disablePadding>
                {nonListVolumes.map(volume => {
                    return (
                        <ListItem key={volume.url}>
                            <Hidden smDown>
                                <ListItemIcon>
                                    <Icon
                                        name={"null"}
                                        color="inherit"
                                    />
                                </ListItemIcon>
                            </Hidden>
                            <Typography
                                component="span"
                                variant="body1">
                                <StyledLink
                                    href={volume.url}
                                    onClick={onCloseClick}>
                                    {volume.name}
                                </StyledLink>
                            </Typography>
                        </ListItem>
                    )
                })}
            </List>
        </>
    )
}

const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.common.white
}))
