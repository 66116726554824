import { Icon } from "components/Icon"
import Notification from "components/ProfilePopover/User/Notification"
import {
    ProfileTabType,
    StyledDefaultHeader
} from "components/ProfilePopover/User/ProfileUser"
import { t } from "locales"
import { useSnackbar } from "notistack"

import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"

import {
    useGetNotifications,
    useMarkNotificationAsRead
} from "data/contentData/hooks/notification.hooks"

export function NotificationsTab({
    changeTab
}: {
    changeTab: (tab: ProfileTabType) => () => void
}) {
    const { enqueueSnackbar } = useSnackbar()
    const { mutate: markAsRead } = useMarkNotificationAsRead()
    const { status, data: notifications } = useGetNotifications()

    const handleMarkAsRead = (id: number) => () => {
        markAsRead({ id })
        enqueueSnackbar(t["notifications"]["notification-was-removed"], {
            variant: "info"
        })
    }

    if (status === "loading") {
        return (
            <List>
                {[...Array(3).keys()].map(id => (
                    <ListItem
                        divider
                        key={id}>
                        <ListItemText
                            primary={
                                <Skeleton
                                    height={24}
                                    width={150}
                                />
                            }
                            secondary={
                                <Skeleton
                                    height={20}
                                    width={65}
                                />
                            }
                        />
                    </ListItem>
                ))}
            </List>
        )
    }

    const noNotifications =
        status === "success" && (!notifications || !notifications.length)

    return (
        <>
            <StyledDefaultHeader>
                <IconButton
                    size="small"
                    onClick={changeTab("default")}>
                    <Icon
                        name="arrowBack"
                        fontSize="small"
                    />
                </IconButton>
                {t.profile.notifications}
            </StyledDefaultHeader>
            {noNotifications ? (
                <Typography variant="caption">
                    {t["notifications"]["no-new-notifications"]}
                </Typography>
            ) : (
                <List>
                    {notifications?.map((notification: any) => (
                        <Notification
                            key={notification.id}
                            {...notification}
                            onMarkAsRead={handleMarkAsRead(notification.id)}
                        />
                    ))}
                </List>
            )}
        </>
    )
}
