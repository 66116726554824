import useUser from "hooks/useUser"
import { useCallback, useMemo } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { OptionsType } from "types/shared.types"

import {
    FavoriteSorterType,
    IFavorite,
    IUserSettings,
    getUserSettings,
    updateUserSettings
} from "data/contentData/api/user"
import isEnabled from "lib/is-enabled"

export enum UserCacheKeys {
    USER_SETTINGS = "user-settings"
}

export function useGetUserSettings(
    options?: OptionsType<IUserSettings | undefined>
) {
    const { authenticated, accessToken, isFetching } = useUser()

    return useQuery<IUserSettings | undefined, Error>(
        [UserCacheKeys.USER_SETTINGS],
        () => getUserSettings(accessToken),
        {
            ...options,
            enabled: isEnabled(authenticated && !isFetching, options)
        }
    )
}

export function useUpdateUserSettings() {
    const queryClient = useQueryClient()
    const { accessToken } = useUser()

    return useMutation(
        (settings: IUserSettings) => updateUserSettings(settings, accessToken),
        {
            onMutate: async settings => {
                // Cancel outgoing queries
                await queryClient.cancelQueries([UserCacheKeys.USER_SETTINGS])

                // Snapshot previous data
                const previousData = queryClient.getQueryData<IUserSettings>([
                    UserCacheKeys.USER_SETTINGS
                ])

                // Optimistic update
                if (previousData) {
                    queryClient.setQueryData([UserCacheKeys.USER_SETTINGS], {
                        ...settings
                    })
                }
                return { previousData: settings }
            },
            onError: (_, __, context: any) => {
                // revert if error
                if (context?.previousData) {
                    queryClient.setQueryData(
                        [UserCacheKeys.USER_SETTINGS],
                        context.previousData
                    )
                }
            },
            onSuccess: () => {
                queryClient.invalidateQueries(UserCacheKeys.USER_SETTINGS)
            }
        }
    )
}

type DrawerSorterFn = (a: IFavorite, b: IFavorite) => number

export const sorters: Record<FavoriteSorterType, DrawerSorterFn> = {
    abc: (a, b) => a.name.localeCompare(b.name),
    time: (a, b) =>
        new Date(b.timestamp as Date).valueOf() -
        new Date(a.timestamp as Date).valueOf(),
    template: (a, b) => a.template.localeCompare(b.template)
}

/**
 * Hook to add current page to favorites,
 * or remove if already added
 */
export function useFavorites(id?: string): {
    favorite?: IFavorite
    favorites: IFavorite[]
    toggle: (favorite?: IFavorite) => void
    add: (favorite: IFavorite) => void
    remove: (id: string) => void
    sort: (sortBy: FavoriteSorterType) => void
    sortBy: FavoriteSorterType
    loading: boolean
} {
    const { data: userSettings } = useGetUserSettings()
    const { mutate, isLoading } = useUpdateUserSettings()

    const sortBySetting = userSettings?.favorites?.sortBy ?? "abc"
    const list = useMemo(
        () => userSettings?.favorites?.list ?? [],
        [userSettings?.favorites?.list]
    )
    const favorites = list.sort(sorters[sortBySetting])

    const remove = useCallback(
        (url: string) => {
            const newUserSettings = {
                ...userSettings,
                favorites: {
                    sortBy: sortBySetting,
                    list: list.filter(p => p.url !== url)
                }
            }

            mutate(newUserSettings)
        },
        [list, mutate, sortBySetting, userSettings]
    )

    const add = useCallback(
        (favorite: IFavorite) => {
            if (!favorite.timestamp) {
                favorite.timestamp = new Date()
            }

            const newUserSettings = {
                ...userSettings,
                favorites: {
                    sortBy: sortBySetting,
                    list: [...list, favorite]
                }
            }
            mutate(newUserSettings)
        },
        [list, mutate, sortBySetting, userSettings]
    )

    const getFavorite = useCallback(
        (passedId: string) => {
            return list.find(f => f.url === passedId)
        },
        [list]
    )

    const toggle = useCallback(
        (favorite?: IFavorite) => {
            if (!favorite?.name) {
                return
            }
            const added = getFavorite(favorite.url)
            if (added) {
                remove(favorite.url)
            } else {
                add(favorite)
            }
        },
        [add, getFavorite, remove]
    )

    const sort = useCallback(
        (sortBy: FavoriteSorterType) => {
            const newUserSettings = {
                ...userSettings,
                favorites: {
                    list,
                    sortBy: sortBy
                }
            }
            mutate(newUserSettings)
        },
        [list, mutate, userSettings]
    )

    return {
        add,
        favorite: id ? getFavorite(id) : undefined,
        favorites,
        loading: isLoading,
        remove,
        sort,
        sortBy: sortBySetting,
        toggle
    }
}
