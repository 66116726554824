import { styled } from "@mui/material/styles"

import theme from "lib/theme"

type Props = {
    children?: React.ReactNode
    open?: boolean
    color?: string
    background?: string
}

const defaultColor = theme.palette.common.white
const defaultBackground = theme.palette.quaternary.main

export default function Avatar({
    children,
    color = defaultColor,
    background = defaultBackground
}: Props) {
    return (
        <StyledAvatar
            color={color}
            background={background}>
            {children}
        </StyledAvatar>
    )
}

type AvatarProps = {
    color: string
    background: string
}

const StyledAvatar = styled("div")<AvatarProps>(({ color, background }) => ({
    backgroundColor: background,
    color: color,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: 700,
    height: "40px",
    lineHeight: "18px",
    width: "40px"
}))
