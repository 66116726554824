import FooterItem from "components/Layout/Footer/FooterCard"
import { useEffect, useRef, useState } from "react"

import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import { styled } from "@mui/material/styles"

import { useGetFooterCards } from "data/contentData/hooks/content.hooks"
import useIntersection from "hooks/useIntersection"

export default function Footer() {
    const ref = useRef<HTMLDivElement>(null)
    const a = useIntersection(ref, { rootMargin: "0px", threshold: 0.1 })

    const [soonInView, setSoonInView] = useState(false)
    const isIntersecting = a?.isIntersecting

    useEffect(() => {
        if (isIntersecting && !soonInView) {
            setSoonInView(true)
        }
    }, [soonInView, isIntersecting])

    const { data: footer } = useGetFooterCards({
        enabled: soonInView
    })

    const content = footer ? (
        footer?.map(card => (
            <FooterItem
                key={card.contentId}
                {...card}
            />
        ))
    ) : (
        <FooterSkeleton />
    )

    return (
        <StyledFooter className="no-print">
            <div
                ref={ref}
                style={{
                    position: "absolute",
                    top: "-800px",
                    width: 0,
                    height: 0
                }}
            />
            <Container
                maxWidth="lg"
                sx={{ px: 2 }}>
                <Grid
                    container
                    spacing={4}>
                    {content}
                </Grid>
            </Container>
        </StyledFooter>
    )
}

const StyledFooter = styled("footer")(({ theme }) => ({
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    minHeight: theme.constants.height.footerMin,
    padding: theme.spacing(4, 0)
}))

function FooterSkeleton() {
    return (
        <Grid
            container
            spacing={4}>
            <Grid
                item
                md={4}
                xs={12}>
                <Skeleton
                    width={"100%"}
                    height={400}
                />
            </Grid>
            <Grid
                item
                md={4}
                xs={12}>
                <Skeleton
                    width={"100%"}
                    height={400}
                />
            </Grid>
            <Grid
                item
                md={4}
                xs={12}>
                <Skeleton
                    width={"100%"}
                    height={400}
                />
            </Grid>
        </Grid>
    )
}
