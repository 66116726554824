import { PortableTextTypeComponentProps } from "@portabletext/react"
import { t } from "locales"

export default function Audio(
    props: PortableTextTypeComponentProps<HTMLAudioElement>
) {
    return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio {...props}>{t["audio-fallback"]}</audio>
    )
}
