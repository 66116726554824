import { PortableTextTypeComponentProps } from "@portabletext/react"
import NextImage from "next/image"
import { forwardRef } from "react"

import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

export interface IContentImage {
    alt?: string
    caption?: string
    src: string
    title?: string
    noZoom?: boolean
    align?: "none" | "pull-left" | "pull-right"
    size?: "small" | "medium" | "large"
    index: number
    width?: number
    height?: number
}

interface IImgContentProps {
    children: JSX.Element
}

// NOTE:
// The tooltip needs to apply DOM event listeners to its child element.
// If the child is a custom React element, you need to make sure that it spreads its properties to the underlying DOM element.
const ImgContent = forwardRef<HTMLSpanElement, IImgContentProps>(function (
    props: IImgContentProps,
    ref
) {
    return (
        <span
            {...props}
            ref={ref}>
            {props.children}
        </span>
    )
})

ImgContent.displayName = "ImgContent"

type ImgProps = PortableTextTypeComponentProps<IContentImage> & {
    alt?: string
    layout?: "fill"
    button?: boolean
}

export default function Image(props: ImgProps) {
    const {
        value: { src, alt, title, caption, width, height },
        layout,
        button
    } = props

    let component
    if (layout === "fill") {
        component = (
            <ImgContent>
                <NextImage
                    src={src}
                    alt={alt}
                    layout="fill"
                    objectFit="contain"
                />
            </ImgContent>
        )
    } else if (width && height) {
        component = (
            <ImgContent>
                <NextImage
                    src={src}
                    alt={alt}
                    width={width}
                    height={height}
                />
            </ImgContent>
        )
    } else {
        component = (
            <ImgContent>
                <SimpleImg
                    src={src}
                    alt={alt}
                />
            </ImgContent>
        )
    }

    if (caption) {
        component = (
            <ImgContent>
                <figure
                    style={{
                        margin: 0,
                        padding: button ? 0 : undefined,
                        width: button ? "100%" : undefined,
                        overflowWrap: "anywhere"
                    }}>
                    {component}
                    <Typography
                        variant="caption"
                        component="figcaption">
                        {caption}
                    </Typography>
                </figure>
            </ImgContent>
        )
    }

    if (title) {
        return <Tooltip title={title}>{component}</Tooltip>
    }

    return component
}

const SimpleImg = styled("img")(() => ({
    maxWidth: "100%",
    height: "auto"
}))
