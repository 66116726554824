import { Icon } from "components/Icon"
import { LoginButton } from "components/ProfilePopover/LoginButton"
import ProfileOrganisation from "components/ProfilePopover/ProfileOrganisation"
import ProfileUser from "components/ProfilePopover/User/ProfileUser"
import Avatar from "components/UI/Avatar"
import useUser from "hooks/useUser"
import { t } from "locales"
import { MouseEvent, useState } from "react"

import IconButton from "@mui/material/IconButton"
import Popover from "@mui/material/Popover"
import Skeleton from "@mui/material/Skeleton"
import Tooltip from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"

import { useGetOrganisation } from "data/customerManager/hooks/organisation.hooks"
import EmiliaTheme from "lib/theme"

/**
 * 1. Show login button if user is not authenticated
 * 2. Show user profile if user is authenticated
 * 3. Show organisation profile if user is not authenticated but organisation is authenticated
 * @returns
 */
export default function Profile() {
    const { user, authenticated, isLoading } = useUser()

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined

    const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const avatarColors = open
        ? {
              background: EmiliaTheme.palette.common.white,
              color: EmiliaTheme.palette.primary.main
          }
        : {
              background: EmiliaTheme.palette.quaternary.main,
              color: EmiliaTheme.palette.common.white
          }

    const { isLoading: organisationIsLoading, ...organisation } =
        useGetOrganisation()

    if (isLoading || organisationIsLoading) {
        return (
            <Skeleton
                width={120}
                height={60}
            />
        )
    }

    const isAuthenticated = authenticated || organisation.authenticated

    if (!isAuthenticated) {
        return <LoginButton />
    }

    const name: string = user?.name ?? organisation?.name ?? ""
    const nameArray = name.split(" ")
    const initials = nameArray
        .map(n => n[0])
        .slice(0, 2)
        .join("")
    const firstName = nameArray[0]
    const restOfName = nameArray.slice(1).join(" ")

    const ProfileComponent =
        organisation.authenticated && !user ? (
            <ProfileOrganisation name={organisation.name ?? ""} />
        ) : (
            <ProfileUser />
        )

    if (isAuthenticated) {
        return (
            <>
                <StyledWrapper>
                    <Tooltip title={t.header.profile}>
                        <StyledIconButton
                            onClick={handleOpen}
                            size="large"
                            aria-label={t.header.profile}>
                            <Avatar
                                color={avatarColors.color}
                                background={avatarColors.background}>
                                {initials}
                            </Avatar>
                        </StyledIconButton>
                    </Tooltip>
                    <StyledName>
                        <span>{firstName}</span>
                        <StyledRestOfName>{restOfName}</StyledRestOfName>
                    </StyledName>
                </StyledWrapper>
                <StyledPopover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}>
                    {ProfileComponent}
                    <StyledCloseButton onClick={handleClose}>
                        <Icon
                            name="close"
                            fontSize="small"
                        />
                    </StyledCloseButton>
                </StyledPopover>
            </>
        )
    }

    return null
}

const StyledIconButton = styled(IconButton)(() => ({
    fontSize: "20px",
    padding: "4px"
}))

const StyledWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1)
}))

const StyledName = styled("div")(({ theme }) => ({
    flexDirection: "column",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 700,
    justifyContent: "center",
    display: "none",
    [theme.breakpoints.up("md")]: {
        display: "flex"
    }
}))

const StyledRestOfName = styled("span")(() => ({
    fontWeight: 400
}))

const StyledPopover = styled(Popover)(({ theme }) => ({
    " > .MuiPaper-root": {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            minWidth: "500px",
            maxWidth: "50vw"
        },
        [theme.breakpoints.up("md")]: {
            minWidth: "500px",
            maxWidth: "30vw"
        }
    }
}))

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2)
}))
