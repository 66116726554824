import { Icon } from "components/Icon"
import { rewrites } from "config/config"
import { t } from "locales"

import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"

import { signOut } from "lib/next-auth"
import { isLocalStorageSupported } from "lib/storage/is-localstorage-supported"

export function LogoutButton() {
    return (
        <StyledLogoutButton
            startIcon={<Icon name="logout" />}
            variant="outlined"
            onClick={() => {
                if (isLocalStorageSupported()) {
                    localStorage.removeItem("intro-visited")
                    localStorage.removeItem("external-link-popup-disabled")
                }
                if (window.location.href.includes("localhost")) {
                    signOut({
                        callbackUrl: rewrites["/logged-out"]
                    })
                } else {
                    sessionStorage.setItem("force-logout", "1")
                    window.location.href = "/api/auth/federated-logout"
                }
            }}>
            {t.logout}
        </StyledLogoutButton>
    )
}

const StyledLogoutButton = styled(Button)(() => ({
    alignSelf: "flex-start"
}))
