// Inspired by: https://github.com/reach/reach-ui/tree/main/packages/skip-nav
import { t } from "locales"

import Link from "@mui/material/Link"
import { styled } from "@mui/material/styles"

const defaultId = "skip-nav"

/**
 * Renders a link that remains hidden until focused to skip to the main content.
 * @returns {JSX.Element}
 */
export default function SkipNavLink() {
    return (
        <StyledSkipNavLink href={`#${defaultId}`}>
            {t["skip-to-main-content"]}
        </StyledSkipNavLink>
    )
}

const StyledSkipNavLink = styled(Link)(({ theme }) => ({
    marginRight: theme.spacing(2),
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    padding: 0,
    position: "absolute",
    clip: "rect(0 0 0 0)",
    overflow: "hidden",
    transition: "transform 0.3s",
    "&:focus": {
        clip: "auto",
        height: "auto",
        width: "auto",
        position: "fixed",
        zIndex: 99999,
        padding: theme.spacing(3),
        top: theme.spacing(3),
        left: theme.spacing(3)
    }
}))

/**
 * Renders a div as the target for the link.
 * @returns {JSX.Element}
 */
export function SkipNavContent() {
    return <StyledSkipNavContent id={defaultId} />
}

const StyledSkipNavContent = styled("div")(({ theme }) => ({
    scrollMarginTop: `${
        theme.constants.height.header + theme.constants.height.breadcrumbs
    }px`
}))
