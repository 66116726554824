import { captureException } from "@sentry/nextjs"
import { Drawer } from "components/Drawer/Drawer"
import { Icon } from "components/Icon"
import { general, staticPaths } from "config/config"
import usePath from "hooks/usePath"
import useUser from "hooks/useUser"
import { t } from "locales"
import Head from "next/head"
import { useRouter } from "next/router"
import { useSnackbar } from "notistack"
import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react"

import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { GROUP_IDS, ITicket } from "data/contentData/api/ticket"
import { useCreateTicket } from "data/contentData/hooks/ticket.hooks"

function getReCaptchaToken(action = "homepage") {
    try {
        return window.grecaptcha.execute(general.recaptchaId, {
            action
        })
    } catch (error) {
        captureException(error)
    }
}

const defaultTicket: ITicket = {
    body: "",
    email: "",
    groupId: "114095479893",
    name: "",
    subject: "",
    url: ""
}

export default function Feedback() {
    const { authenticated, user } = useUser()
    const [ticket, setTicket] = useState(defaultTicket)

    const router = useRouter()
    const path = usePath()

    const showFeedback = Boolean(router.query["show-feedback"])
    const [anchorEl, setAnchorEl] = useState<null | any>(null)
    const [open, setOpen] = useState(showFeedback)
    useEffect(() => {
        if (showFeedback) {
            setOpen(true)
        }
    }, [showFeedback])

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)

        if (router.query["show-feedback"]) {
            const { "show-feedback": _showFeedback, ...queryParams } =
                router.query

            router.push(
                {
                    query: { ...queryParams }
                },
                path + sanitizeSearchParams(router.asPath),
                { shallow: true }
            )
        }
    }

    const handleChange = ({
        target: { value, name }
    }: ChangeEvent<HTMLInputElement>) => {
        setTicket(prev => ({ ...prev, [name]: value }))
    }

    const { enqueueSnackbar } = useSnackbar()

    const { mutate: addTicket, isLoading } = useCreateTicket()

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const token = await getReCaptchaToken("ticket")
        if (!token) {
            enqueueSnackbar(<ErrorSnackbar />, {
                variant: "error",
                persist: true
            })
            return
        }

        const newTicket: ITicket = {
            ...ticket,
            subject: `${t["feedback"][GROUP_IDS[ticket.groupId]]}: ${
                ticket.subject
            }`,
            url: window.location.href
        }

        if (user) {
            newTicket.name = user.name
            newTicket.email = user.email
        }

        addTicket(newTicket, {
            onError: () => {
                enqueueSnackbar(<ErrorSnackbar />, {
                    variant: "error",
                    persist: true
                })
            },
            onSuccess: () => {
                enqueueSnackbar(t["feedback"]["ticket-sent"], {
                    variant: "info"
                })
                setTicket(defaultTicket)
                handleClose()
            }
        })
    }

    if (general.feedback.type === "article") {
        return (
            <Tooltip title={t.header.feedback}>
                <IconButton
                    href={staticPaths["/contact"]}
                    aria-label={t.header.feedback}
                    color="inherit"
                    sx={{ p: 0.5 }}
                    size="large">
                    <Icon name="feedback" />
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <>
            <Tooltip title={t.header.feedback}>
                <IconButton
                    ref={anchorEl}
                    onClick={handleOpen}
                    aria-label={t.header.feedback}
                    color="inherit"
                    sx={{ p: 0.5 }}
                    size="large">
                    <Icon name="feedback" />
                </IconButton>
            </Tooltip>
            <Drawer
                header={
                    <Typography
                        align="center"
                        variant="subtitle2">
                        {t.feedback.title}
                    </Typography>
                }
                position="right"
                open={open}
                onClose={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Head>
                        <script
                            async
                            src={`https://www.google.com/recaptcha/api.js?render=${general.recaptchaId}`}
                        />
                    </Head>
                    <Grid
                        container
                        direction="column"
                        spacing={2}>
                        {authenticated ? null : (
                            <>
                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        fullWidth
                                        id="name"
                                        label={t.feedback.name}
                                        name="name"
                                        onChange={handleChange}
                                        value={ticket.name}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label={t.feedback.email}
                                        name="email"
                                        onChange={handleChange}
                                        type="email"
                                        value={ticket.email}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                fullWidth
                                select
                                id="demo-simple-select-outlined"
                                label={t.feedback.groupId}
                                name="groupId"
                                onChange={handleChange}
                                value={ticket.groupId}
                                defaultValue={general.feedback.defaultValue}
                                variant="outlined">
                                {Object.entries(GROUP_IDS).map(
                                    ([id, translationKey]) => (
                                        <MenuItem
                                            key={id}
                                            value={id}>
                                            {t["feedback"][translationKey]}
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        </Grid>
                        <Grid
                            item
                            xs={12}>
                            <TextField
                                fullWidth
                                id="subject"
                                label={t.feedback.subject}
                                name="subject"
                                onChange={handleChange}
                                value={ticket.subject}
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                id="body"
                                label={t.feedback.body}
                                name="body"
                                onChange={handleChange}
                                rows={4}
                                value={ticket.body}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            justifyContent="center"
                            xs={12}>
                            <Button
                                disabled={isLoading}
                                color="secondary"
                                type="submit"
                                variant="outlined">
                                {t.feedback.send}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Drawer>
        </>
    )
}

const Form = styled("form")(({ theme }) => ({
    padding: theme.spacing(4, 2)
}))

function sanitizeSearchParams(asPath: string) {
    if (asPath === "") return ""

    const fullURL = new URL(general.appHost + asPath)

    const searchParams = new URLSearchParams(fullURL.search)

    searchParams.delete("show-feedback")

    return searchParams.toString().length > 0 ? `?${searchParams}` : ""
}

function ErrorSnackbar() {
    const { closeSnackbar } = useSnackbar()
    return (
        <StyledSnackbar>
            <Typography variant="body2">
                {t["feedback"]["error-sending-ticket"]}
            </Typography>

            <IconButton
                aria-label={t["close-snackbar"]}
                color="inherit"
                onClick={() => closeSnackbar()}
                size="large">
                <Icon
                    name="close"
                    fontSize="small"
                />
            </IconButton>
        </StyledSnackbar>
    )
}

const StyledSnackbar = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2)
}))
