import { Icon } from "components/Icon"
import { DictionarySwitch } from "components/ProfilePopover/User/DictionarySwitch"
import {
    ProfileTabType,
    StyledDefaultHeader
} from "components/ProfilePopover/User/ProfileUser"
import RegionSelect from "components/ProfilePopover/User/RegionSelect"
import { general } from "config/config"
import { t } from "locales"

import IconButton from "@mui/material/IconButton"
import { styled } from "@mui/material/styles"

export function SettingsTab({
    changeTab
}: {
    changeTab: (tab: ProfileTabType) => () => void
}) {
    return (
        <>
            <StyledDefaultHeader>
                <IconButton
                    size="small"
                    onClick={changeTab("default")}>
                    <Icon
                        name="arrowBack"
                        fontSize="small"
                    />
                </IconButton>
                {t.profile.settings}
            </StyledDefaultHeader>
            <StyledWrapper>
                {general.enableRegionalContent && <RegionSelect />}
                {general.enableDictionary && <DictionarySwitch />}
            </StyledWrapper>
        </>
    )
}

const StyledWrapper = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem"
}))
