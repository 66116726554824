import { AccessTokenResponseType, AuthConfigType } from "auth"
import { auth } from "config/config"
import { JWT } from "next-auth/jwt"
import AzureADB2CProvider, {
    AzureB2CProfile
} from "next-auth/providers/azure-ad-b2c"

const tenantName = process.env.ADB2C_TENANT_NAME
const baseUrl = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com`
const userFlow = "B2C_1_Default_Login"

/**
 * Access token url for server side requests
 */
const accessTokenUrl = `${baseUrl}/${userFlow}/oauth2/v2.0/token`

/**
 * Edit profile url - used to navigate user to edit profile page
 */
const editProfileUrl = `${baseUrl}/oauth2/v2.0/authorize?p=B2C_1_EditProfile&client_id=${auth.clientId}&nonce=defaultNonce&redirect_uri=${process.env.NEXTAUTH_URL}/auth/signout&scope=openid&response_type=id_token&prompt=login`

/**
 * End session url - used to log user out from Azure AD B2C
 */
const endSessionUrl = `${baseUrl}/${userFlow}/oauth2/v2.0/logout`

/**
 * Azure AD B2C provider setup for next-auth
 */
const provider = AzureADB2CProvider({
    id: "azure-ad-b2c",
    version: "2.0",
    tenantId: process.env.ADB2C_TENANT_NAME,
    clientId: auth.clientId,
    clientSecret: "",
    primaryUserFlow: userFlow,
    wellKnown: `${baseUrl}/${userFlow}/v2.0/.well-known/openid-configuration`,
    authorization: {
        url: `${baseUrl}/${userFlow}/oauth2/v2.0/authorize?response_type=code+id_token+token&response_mode=form_post`,
        params: {
            grant_type: "authorization_code",
            scope: "offline_access openid https://medibas.pl/emilia/use"
        }
    },
    accessTokenUrl,
    checks: ["pkce"],
    idToken: true,
    profileUrl: "https://graph.microsoft.com/oidc/userinfo",

    profile(p) {
        const profile = p as AzureB2CProfile

        const roleOrRoles = profile["extension_roles"].split(" ") ?? []
        const roles = (
            Array.isArray(roleOrRoles) ? roleOrRoles : [roleOrRoles]
        ).filter(role => role.startsWith("Handbook."))

        const concurrency = parseInt(
            profile["extension_handbookConcurrency"] ||
                auth.defaultMaxConcurrentLogins.toString(),
            10
        )

        return {
            id: profile.sub ?? "",
            guid: profile["extension_customerGuid"] ?? "",
            parentId: profile["extension_parentGuid"],
            email: profile.emails?.[0] ?? "",
            organisationAffiliation:
                profile["extension_organisationalAffiliation"],
            professionName: profile["extension_professionName"] ?? "",
            name: profile["extension_fullname"],
            concurrency,
            roles
        }
    }
})

async function getServerSideAccessToken(): Promise<AccessTokenResponseType> {
    const url = "https://login.microsoftonline.com/nhi.no/oauth2/v2.0/token"

    const response = await fetch(url, {
        body: new URLSearchParams({
            client_id: process.env.ENTRA_ID_CLIENT_ID,
            client_secret: process.env.ENTRA_ID_SERVER_SIDE_SECRET,
            tenant_id: process.env.ENTRA_ID_TENANT_ID,
            grant_type: "client_credentials",
            scope: process.env.ENTRA_ID_SERVER_SIDE_SCOPE
        }),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "post"
    })

    const accessToken: AccessTokenResponseType = await response.json()

    if (response.ok) {
        return {
            ...accessToken,
            // Give a 10 sec buffer
            expires_in: accessToken.expires_in - 10
        }
    }

    throw {
        message: "AccessTokenError",
        accessToken
    }
}

async function getUpdatedRoles(token: JWT) {
    throw new Error(`Not implemented for this provider..., ${token}`)
    return new Promise<string[]>(() => [])
}

export const azureAdConfig: AuthConfigType = {
    provider,
    accessTokenUrl,
    editProfileUrl,
    endSessionUrl,
    getServerSideAccessToken,
    getUpdatedRoles
}
