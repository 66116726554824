import { useQuery } from "react-query"
import { OptionsType } from "types/shared.types"

import {
    IDictionaryWord,
    IFragment,
    getDictionary,
    getDictionaryTerm,
    getFragment,
    getRegions
} from "data/contentData/api/config"
import { PaywallType } from "data/contentData/api/content"

export enum ConfigCacheKeys {
    BANNERS = "banners",
    REGIONS = "regions",
    PAYWALL = "paywall",
    INFORMATION_NONSUBSCRIBER = "informationNonSubscriber",
    MEGAMENU = "megamenu",
    DICTIONARY = "dictionary"
}

export function useGetRegions(options?: OptionsType<string[]>) {
    return useQuery<string[], Error>(
        [ConfigCacheKeys.REGIONS],
        async () => {
            return await getRegions()
        },
        {
            staleTime: 1000 * 60 * 60 * 8, // 8 hours
            ...options
        }
    )
}

export function useGetPaywall(
    type: PaywallType,
    options?: OptionsType<IFragment | null>
) {
    const path = `/paywall/${type}`
    return useQuery<IFragment | null, Error>(
        [ConfigCacheKeys.PAYWALL, path],
        async () => await getFragment(path),
        {
            staleTime: 1000 * 60 * 60 * 8, // 8 hours
            ...options
        }
    )
}

export function useGetNonSubscriberInformation(
    options?: OptionsType<IFragment | null>
) {
    const path = "/information/non-subscribers"

    return useQuery<IFragment | null, Error>(
        [ConfigCacheKeys.PAYWALL, path],
        async () => await getFragment(path),
        {
            staleTime: 1000 * 60 * 60 * 8, // 8 hours
            ...options
        }
    )
}

export function useGetDictionary(options?: OptionsType<IDictionaryWord[]>) {
    return useQuery<IDictionaryWord[], Error>(
        [ConfigCacheKeys.DICTIONARY],
        async () => await getDictionary(),
        {
            ...options
        }
    )
}

export function useGetDictionaryTerm(
    term: string | undefined,
    options?: OptionsType<IDictionaryWord | null>
) {
    return useQuery<IDictionaryWord | null, Error>(
        [ConfigCacheKeys.DICTIONARY, term],
        async () => await getDictionaryTerm(term),
        {
            ...options
        }
    )
}
