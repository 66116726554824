import { Icon } from "components/Icon"
import { t } from "locales"
import { useState } from "react"

import Button from "@mui/material/Button"
import Collapse from "@mui/material/Collapse"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"
import { styled } from "@mui/material/styles"
import { toLocalDate } from "lib/string/date-to-locale-date-string"

interface INotificationProps {
    id: number
    subject: string
    body: string
    createdUtc: Date
    onMarkAsRead: () => void
}

export default function Notification({
    subject,
    body,
    createdUtc,
    onMarkAsRead
}: INotificationProps) {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemText
                    primary={toLocalDate(
                        createdUtc,
                        process.env.NEXT_PUBLIC_LOCALE
                    )}
                    primaryTypographyProps={{
                        color: grey[600],
                        variant: "body2"
                    }}
                    secondary={subject}
                    secondaryTypographyProps={{
                        color: "text",
                        variant: "body1"
                    }}
                />
                {open ? <Icon name="expandLess" /> : <Icon name="expandMore" />}
            </ListItemButton>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit>
                <Message>
                    <Typography
                        component="div"
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: body }}
                    />
                    <Actions>
                        <Button
                            startIcon={<Icon name="check" />}
                            variant="text"
                            color="secondary"
                            onClick={onMarkAsRead}>
                            {t.notifications["remove-notification"]}
                        </Button>
                    </Actions>
                </Message>
            </Collapse>
        </>
    )
}

const Message = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2)
}))

const Actions = styled("div")(() => ({
    display: "flex",
    justifyContent: "flex-end"
}))
